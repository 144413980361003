body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sectionDivider {
  width: 100%;
  height: 1px;
  background-color: #e9ecef;
  margin-top: 25px;
  margin-bottom: 15px;
}

a {
  color: #8ca78b;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #9fbba0;
}

ul {
  margin: 0;
  padding: 20px;
}
